import React from 'react'
import { Link } from 'streamr-ui'
import Ui from './Ui'
import graph from './graph.svg'
import graphVertical from './graph_vertical.svg'
import Gallery from './Gallery'
import poster from './poster.jpg'
import Layout, { titleCss, CaseStudySpacer } from '~shared/CaseStudyLayout'
import CaseStudyVideo, { Wrapper as VideoWrapper } from '~shared/CaseStudyVideo'
import CaseStudyTitle from '~shared/CaseStudyTitle'
import CaseStudyTextWrapper from '~shared/CaseStudyTextWrapper'
import CaseStudyQuote from '~shared/CaseStudyQuote'
import CaseStudyLinks from '~shared/CaseStudyLinks'
import CaseStudyReadMore from '~shared/CaseStudyReadMore'
import Graph from '~shared/Graph'
import CaseStudyPartnerButton from '~shared/CaseStudyPartnerButton'
import urls from '~utils/urls'

const Tracey = () => (
    <Layout>
        <CaseStudyTitle
            name="Tracey"
            summary="Towards sustainable &amp; traceable fisheries"
            css={titleCss}
        />
        <VideoWrapper>
            <CaseStudyVideo study="tracey" poster={poster} />
        </VideoWrapper>
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                Streamr’s commercial subsidiary TX is tackling a complex set of linked environmental
                issues with an interesting new pilot project called Tracey. In partnership with WWF
                Philippines and UnionBank, TX aims to make fisheries in the Philippines more
                sustainable by establishing a chain of provenance over the fish caught and rewarding
                fisherpeople for providing the necessary&nbsp;data.
            </p>
            <p $appear>
                Historically, many fisherfolk are unbanked, and their seasonal profession means
                that, according to WWF, two out of five fishers in the Philippines live below the
                poverty line. To bridge the gaps between fishing seasons, Filipino fisherfolk need
                access to microloans that can support other business ventures, but they are often
                perceived as too much of a high risk for&nbsp;banks.
            </p>
            <p $appear>
                The Tracey app de-risks the lending process and provides UnionBank with sufficient
                information about potential clients. Fisherfolk receive accurate credit scores after
                frequently inputting verified catch and trade data. Checks and balances within the
                fisheries ecosystem mean that fraudulent data input can be&nbsp;mitigated with a
                variety of&nbsp;methods.
            </p>
        </CaseStudySpacer>
        <Ui />
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                TX is consulting with fisherpeople at two WWF fishery improvement sites at Bicol and
                Malilipot in the Philippines, to develop an Android app for them that will allow
                them to photograph and geo-tag their catches, as well as recording the sale of the
                catch. The focus for the app is for it to be a simple tool tailored to make the data
                input process as easy as possible, as well as allowing for loan&nbsp;tracking.
            </p>
        </CaseStudySpacer>
        <CaseStudyQuote author="Joann Binondo, Project Manager, WWF&nbsp;Philippines" $appear>
            What we have now is a paper base. We want to have electronic catch documentation which
            is why&nbsp;we are collaborating with providers like TX and&nbsp;Streamr.
        </CaseStudyQuote>
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                The catch and trade data will be fed into the Streamr Network, where it will
                available for anyone around the globe. UnionBank will use this data it to assess
                eligibility for off-season loans to fishers, which are made using their own Peso
                stablecoin. They will also provide Know Your Client (KYC) support for the app, and a
                digital wallet to receive loans and revenue share.
            </p>
        </CaseStudySpacer>
        <Graph
            src={graph}
            verticalSrc={graphVertical}
            caption="Data and revenue flows for the Tracey project"
        />
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                During the concept development phase, the TX team conducted interviews with
                fisherfolk on-site to inform the future design of the Tracey app. The first pilot is
                now planned for mid-2020. This will take place at the WWF-Philippines’ Fishery
                Improvement Project (FIP) sites in Bicol and Mindoro. If the first pilot is
                successful, the aim is to extend to all FIP sites in South East&nbsp;Asia,&nbsp;in
                connection with local&nbsp;banks.
            </p>
            <p $appear>
                Streamr provides the infrastructure for Tracey. The app is to be&nbsp;built on
                top&nbsp;of&nbsp;the Streamr stack, leveraging the Streamr Network, an open source,
                decentralized data transportation layer&nbsp;for real-time data flows, connecting
                Tracey to The Hub where third parties can then subscribe to the data stream. The
                income generated from the data sales will be sent directly to the data producing
                fisherfolk, not via a third party.
            </p>
        </CaseStudySpacer>
        <Gallery />
        <CaseStudySpacer as={CaseStudyTextWrapper}>
            <p $appear>
                Tracey is still in its early pilot stages, but&nbsp;it&nbsp;shows the potential for
                global scale problems to be solved with innovative applications of Streamr&apos;s
                open source Network and tools for sharing and monetising real-time data. If you
                would like to talk to us about how we could help you build something similar, please
                get in&nbsp;touch.
            </p>
            <p $appear>
                <CaseStudyPartnerButton />
            </p>
        </CaseStudySpacer>
        <CaseStudySpacer
            as={CaseStudyReadMore}
            techStack={
                <CaseStudyLinks title="Tech stack">
                    <Link href="https://streamr.network/discover/data-unions/">Data Unions</Link>
                    <Link href={urls.apps.hub} blank>
                        Hub
                    </Link>
                    <Link href="https://streamr.network/discover/network/">Streamr Network</Link>
                </CaseStudyLinks>
            }
            readMore={
                <CaseStudyLinks title="Learn more">
                    <Link href="https://www.tx.company" blank>
                        Tx.company
                    </Link>
                    <Link
                        href="https://finance.yahoo.com/news/unionbank-wwf-blockchain-help-local-140001862.html"
                        blank
                    >
                        Yahoo Finance article
                    </Link>
                    <Link href="https://wwf.org.ph" blank>
                        WWF Philippines
                    </Link>
                    <Link href="https://www.unionbankph.com" blank>
                        UnionBank
                    </Link>
                </CaseStudyLinks>
            }
        />
    </Layout>
)

export default Tracey
