import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import mock from './mock.png'
import CATCH from './catch.png'
import LOANS from './loans.png'
import TRADE from './trade.png'

const images = {
    CATCH,
    LOANS,
    TRADE,
}

const UnstyledPhonecase = forwardRef(function UnstyledPhonecase({ id, ...props }, ref) {
    return (
        <div {...props} ref={ref}>
            <img src={mock} alt="" />
            <img src={images[id]} alt="" />
            <svg viewBox="0 0 262 480" />
        </div>
    )
})

const Phonecase = styled(UnstyledPhonecase)`
    margin: 0 auto;
    max-width: 200px;
    position: relative;

    img {
        position: absolute;
        left: -7.5%;
        width: 115%;
        top: -3%;
    }

    img,
    svg {
        display: block;
    }

    svg {
        width: 100%;
    }

    @media ${MqTablet} {
        max-width: 160px;
    }

    @media ${MqDesktop} {
        max-width: 264px;
    }
`

export default Phonecase
