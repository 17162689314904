import React, { useState } from 'react'
import styled from 'styled-components'
import { MqDesktop } from 'streamr-ui/css/consts'
import slide0 from './slide0.jpg'
import slide1 from './slide1.jpg'
import slide2 from './slide2.jpg'
import slide3 from './slide3.jpg'
import slide4 from './slide4.jpg'
import slide5 from './slide5.jpg'
import Container from '~shared/Container'
import G, { slideIndex } from '~shared/Gallery'
import Figure from '~shared/Figure'
import Spacer from '~shared/Spacer'

const Gallery = (props) => {
    const [slide, setSlide] = useState(0)

    const index = slideIndex(slide, 3)

    return (
        <Spacer {...props} head="64px,160px,192px" tail="40px,96px,128px" pad>
            <G.Constraint>
                <Container>
                    <Figure>
                        <G.Viewport $appear>
                            <G slide={index} onChange={setSlide}>
                                <G.Item src={slide0} alt="" />
                                <G.Item src={slide1} alt="" />
                                <G.Item src={slide2} alt="" />
                                <G.Item src={slide3} alt="" />
                                <G.Item src={slide4} alt="" />
                                <G.Item src={slide5} alt="" />
                            </G>
                        </G.Viewport>
                        <Spacer as={Figure.Caption} head="32px,64px,80px" pad $appear>
                            WWF, TX and the local fisheries association staff on site
                            in&nbsp;Malilipot,&nbsp;Philippines
                        </Spacer>
                    </Figure>
                </Container>
            </G.Constraint>
        </Spacer>
    )
}

export default styled(Gallery)`
    background-color: #f5f5f5;

    ${Figure.Caption} {
        margin-top: 0;
    }

    ${G.SlideContainer} {
        padding: 0 12px;

        @media ${MqDesktop} {
            padding: 0 20px;
        }
    }
`
