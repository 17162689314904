import React from 'react'
import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import Phonecase from './Phonecase'
import Figure from '~shared/Figure'
import Container from '~shared/Container'

const Wrapper = styled.div`
    background-color: #efefef;
`

const NonMobilePhonecase = styled(Phonecase)`
    display: none;

    @media ${MqTablet} {
        display: block;
    }
`

const Inner = styled.div`
    display: flex;
    justify-content: space-between;
    overflow: hidden;
`

const Cases = styled.div`
    padding-top: 64px;

    @media ${MqTablet} {
        padding-top: 160px;
    }

    @media ${MqDesktop} {
        padding: 192px 40px 0;
    }
`

const Ui = (props) => (
    <Figure {...props}>
        <Wrapper>
            <Container>
                <Cases>
                    <Inner $appearIntersection>
                        <NonMobilePhonecase
                            id="CATCH"
                            $appearDirection="rightwards"
                            $appearImmediate
                        />
                        <Phonecase id="TRADE" $appearDirection="none" $appearImmediate />
                        <NonMobilePhonecase
                            id="LOANS"
                            $appearDirection="leftwards"
                            $appearImmediate
                        />
                    </Inner>
                </Cases>
            </Container>
        </Wrapper>
        <Figure.Caption $appear>Early UI mockups for the Tracey mobile app</Figure.Caption>
    </Figure>
)

export default Ui
