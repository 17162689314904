import styled, { css } from 'styled-components'
import ArrowLeft from './assets/arrow_left.png'
import ArrowRight from './assets/arrow_right.png'
import WhiteArrowLeft from './assets/arrow_left_white.png'
import WhiteArrowRight from './assets/arrow_right_white.png'

const ARROWS = {
    blue: {
        left: ArrowLeft,
        right: ArrowRight,
    },
    white: {
        left: WhiteArrowLeft,
        right: WhiteArrowRight,
    },
}

const COORDS = {
    left: {
        x: 0,
        y: 25,
    },
    right: {
        x: 68,
        y: 25,
    },
}

const ArrowCursor = styled.div`
    ${({ color: c, direction: d }) =>
        (d === 'left' || d === 'right') &&
        css`
            cursor: url(${ARROWS[c][d]}) ${COORDS[d].x} ${COORDS[d].y}, pointer;
        `}
`

ArrowCursor.defaultProps = {
    direction: 'none',
    color: 'blue',
}

export default ArrowCursor
