import React from 'react'
import styled from 'styled-components'
import { AnimatedButton } from '@streamr/streamr-layout'
import Link from '~shared/Link'

const Button = styled(AnimatedButton)`
    && {
        font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: none;
        white-space: nowrap;
    }

    &,
    :active,
    :hover,
    :focus,
    :visited {
        color: #0c009a !important;
    }
`

export default function CaseStudyPartnerButton() {
    return (
        <Button tag={Link} href="mailto:partnerships@streamr.network" noDecoration noHover>
            Contact the Partnerships team
        </Button>
    )
}
