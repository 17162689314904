import React, { useCallback, useRef, useEffect } from 'react'
import Swipeable from '~shared/Swipeable'
import styled, { css } from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import gsap from 'gsap'
import useIsMounted from '~hooks/useIsMounted'
import ArrowCursor from '~shared/ArrowCursor'

const Inner = styled.div`
    transform: translateX(${({ dx }) => dx * 100}%);
    width: 100%;
`

const SlideContainer = styled.div`
    @media ${MqTablet} {
        padding: 0 12px;
    }

    @media ${MqDesktop} {
        padding: 0 20px;
    }
`

const Tape = styled(Swipeable)`
    display: flex;
    justify-content: space-between;
    width: ${({ $numSlides }) => $numSlides * 100}%;

    ${SlideContainer} {
        flex-basis: 100%;
    }
`

export const slideIndex = (at, n) => ((at % n) + n) % n

const windowSize = 5

const UnstyledGallery = ({ children, current, slide, onChange, arrowColor, ...props }) => {
    const childArr = React.Children.toArray(children)

    const count = React.Children.count(children)

    const n = windowSize * 2 - 1

    const springRef = useRef()

    const isMounted = useIsMounted()

    const isSlidingRef = useRef(false)

    useEffect(() => {
        const { current: spring } = springRef

        if (!isSlidingRef.current) {
            return
        }

        if (spring) {
            gsap.to(spring, 0.35, {
                transform: 'translateX(0%)',
                onComplete: () => {
                    if (isMounted()) {
                        isSlidingRef.current = false
                    }
                },
            })
        }
    }, [slide, isMounted])

    const animate = useCallback(
        (forward) => {
            const { current: spring } = springRef

            const { current: isSliding } = isSlidingRef

            const from = forward ? 100 : -100

            if (spring && !isSliding) {
                gsap.set(spring, {
                    transform: `translateX(${from}%)`,
                })
                isSlidingRef.current = true
                onChange((s) => s + (forward ? 1 : -1))
            }
        },
        [onChange],
    )

    const onNext = useCallback(
        (e) => {
            if (e && typeof e.preventDefault === 'function') {
                e.preventDefault()
            }

            animate(true)
        },
        [animate],
    )

    const onPrev = useCallback(
        (e) => {
            if (e && typeof e.preventDefault === 'function') {
                e.preventDefault()
            }

            animate(false)
        },
        [animate],
    )

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div {...props}>
            <Inner dx={-(n - 1) / 2}>
                <div ref={springRef}>
                    <Tape onSwipedLeft={onNext} onSwipedRight={onPrev} $numSlides={n}>
                        {[...Array(n)].map((_, index) => {
                            const key = slide - (n - 1) / 2 + index

                            return (
                                <SlideContainer key={key}>
                                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                                    <ArrowCursor
                                        onClick={
                                            (key < slide && onPrev) ||
                                            (key > slide && onNext) ||
                                            undefined
                                        }
                                        direction={
                                            (key < slide && 'left') ||
                                            (key > slide && 'right') ||
                                            'none'
                                        }
                                        color={arrowColor}
                                    >
                                        {childArr[slideIndex(key, count)]}
                                    </ArrowCursor>
                                </SlideContainer>
                            )
                        })}
                    </Tape>
                </div>
            </Inner>
        </div>
    )
}

const Gallery = styled(UnstyledGallery)`
    margin: 0 auto;
    width: 100%;
`

const Item = styled.img`
    display: block;
    max-width: 100%;
    transition: 350ms opacity;

    ${({ disabled }) =>
        !!disabled &&
        css`
            opacity: 0.5;
        `}

    @media ${MqDesktop} {
        border-radius: 4px;
        overflow: hidden;

        img {
            border-radius: 4px;
        }
    }
`

const Viewport = styled.div`
    margin: 0 auto;

    @media ${MqTablet} {
        max-width: 536px;
    }

    @media ${MqDesktop} {
        max-width: 908px;
    }
`

const Constraint = styled.div`
    width: 100%;
    overflow: hidden;
`

Gallery.defaultProps = {
    arrowColor: 'white',
}

Object.assign(Gallery, {
    Item,
    SlideContainer,
    Viewport,
    Constraint,
})

export default Gallery
