import React from 'react'
import { useSwipeable } from 'react-swipeable'

export default function Swipeable({ onSwipedLeft, onSwipedRight, ...props }) {
    const handlers = useSwipeable({
        onSwipedLeft,
        onSwipedRight,
    })

    return <div {...props} {...handlers} />
}
