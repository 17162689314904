import React from 'react'
import Tracey from '../../components/CaseStudies/Tracey'
import Poster from '../../components/CaseStudies/Tracey/social.jpg'
import page from '~utils/page'
import Head from '~shared/Head'
import { NETWORK } from '~shared/Nav'

// eslint-disable-next-line import/no-unused-modules
export default page(Tracey, {
    highlight: NETWORK,
    headComponent: (
        <Head
            description="Towards sustainable and traceable fisheries"
            mediaTitle="Streamr Case Study — Tracey"
            posterSize={[1920, 1080]}
            posterUrl={Poster}
            title="Streamr - Tracey case study | Fisheries Data Marketplace | Buy &amp; Sell Data"
        />
    ),
})
